import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import descriptionSectionStyle from "assets/jss/material-kit-react/views/landingPageSections/descriptionSectionStyle.jsx";

class ProductSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8}>
            <h2 className={classes.title}>Book Merlin Beta Launch Coming Soon...</h2>
            {/*<img
                src={require('pages/HomePage/img/multipleDevices.png')}
                className={classes.img}
            />*/}
            <h5 className={classes.description}>
              Soon this page will provide you with the ability to sign up for our limited access Beta program.
            </h5>

          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(descriptionSectionStyle)(ProductSection);
